import React, { createContext, useState, useContext } from "react"

const ConsentContext = createContext()

// We use a global provider here because keeping this state inside of `Layout` leads to a bug where
// clicking "Accept all" only works before navigating to any Links, as this re-renders the Layout.
export const ConsentProvider = ({ children }) => {
  const [isConsentGranted, setIsConsentGranted] = useState(false)

  return (
    <ConsentContext.Provider value={{ isConsentGranted, setIsConsentGranted }}>
      {children}
    </ConsentContext.Provider>
  )
}

// Custom hook for consuming the context
export const useConsent = () => {
  const context = useContext(ConsentContext)

  // Return fallback if window is undefined (SSR)
  if (typeof window === "undefined") {
    return { isConsentGranted: false, setIsConsentGranted: () => {} }
  }

  if (!context) {
    throw new Error("useConsent must be used within a ConsentProvider")
  }

  return context
}
