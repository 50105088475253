module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"From Scratch Code","short_name":"From Scratch","description":"Unlock the power of Rust and Python by mastering advanced concepts to build code that demonstrates your expertise and creativity. Offering mentorship and courses to help you create complex libraries, systems, and real-world tools from scratch—all in a supportive and sometimes silly environment.","start_url":"/","background_color":"#0460bd","theme_color":"#0460bd","display":"minimal-ui","icon":"src/images/favicon-512x512.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5ad6a2e1c28ae41df49e9226f41816af"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
